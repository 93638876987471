<template>
  <div class="tw-w-full tw-h-[calc(100%-45px)] md:tw-h-full">
    <div v-if="element" class="sidebar-right-container position-relative tw-overflow-y-auto tw-h-full tw-w-full tw-p-4">
      <zem-card class="tw-flex-col">
        <section class="tw-w-full tw-flex tw-justify-between tw-items-start">
          <div>
            <zem-card-title>{{ element.fio }}</zem-card-title>
            <div class="tw-flex tw-items-center tw-text-xs tw-text-gray-700">
              <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0B9999] tw-rounded-full tw-mr-1"></span>
              ID {{ element.number }}
            </div>
            <div class="tw-flex tw-items-center tw-text-xs tw-text-gray-700">
              <span class="tw-w-1.5 tw-h-1.5 tw-bg-[#0EC6C6] tw-rounded-full tw-mr-1"></span>
              ID {{ element.id }}
            </div>
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <template v-if="!newElement && !editElement">
              <Edit3Icon
                v-if="isShowEditButton && $can('edit', 'human-update')"
                class="text-primary tw-cursor-pointer"
                size="16"
                @click="editElement = true"
              />
              <XIcon class="text-primary tw-cursor-pointer" size="18" @click="closeRightSidebar" />
            </template>
            <template v-else-if="!createItemLoading">
              <zem-link class="mr-2" @click="editElement = false"> Отменить</zem-link>
              <zem-button @click="saveData"> Сохранить</zem-button>
            </template>

            <zem-button v-if="createItemLoading" disabled>
              <zem-preloader color="light" />
            </zem-button>
          </div>
        </section>
        <slot name="bottom"></slot>
      </zem-card>
      <ZemCollapse is-opened-default title="Контакты">
        <div class="zem-collapse-table rows">
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Дата создания</div>
            <div class="zem-collapse-table__column text">
              {{ formatDateTime(element.created_at) }}
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Название</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement">{{ element.title }}</template>
              <ZemInput v-else v-model="element.title" />
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">ФИО</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement">{{ element.fio }}</template>
              <ZemInput v-else v-model="element.fio" />
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Телефон</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement">{{ element.phone }}</template>
              <ZemInput v-else v-model="element.phone" mask="phone" type="phone" />
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Email</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement">{{ element.email }}</template>
              <ZemInput v-else v-model="element.email" />
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">ID в amoCRM</div>
            <div class="zem-collapse-table__column text">
              <template v-if="!editElement">{{ element.amocrm_contact_id }}</template>
              <ZemInput v-else v-model="element.amocrm_contact_id" />
            </div>
          </div>
        </div>
      </ZemCollapse>

      <ZemCollapse v-if="organization" is-opened-default title="Организация">
        <div class="zem-collapse-table rows">
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">Название</div>
            <div class="zem-collapse-table__column text">
              {{ organization.data.title }}
            </div>
          </div>
          <div class="zem-collapse-table__row tw-grid-cols-[1fr_1fr] md:tw-grid-cols-[1fr_2fr]">
            <div class="zem-collapse-table__column title">ИНН</div>
            <div class="zem-collapse-table__column text">
              {{ organization.data.inn }}
            </div>
          </div>
        </div>
      </ZemCollapse>
    </div>

    <preloader-wrapper :show="$store.state.other.isLoadingItem" />
  </div>
</template>

<script>
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'
import CollapseCard from '@/components/CollapseCard.vue'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import ZemCard from '@/components/ui/ZemCard.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemCardTitle from '@/components/ui/ZemCardTitle.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import {formattedPhone, phoneTrim} from '@/assets/scripts/scripts'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import {Edit3Icon, XIcon} from 'vue-feather-icons'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'
import moment from 'moment/moment'
import ZemInput from '@/components/ui/ZemInput.vue'

export default {
  components: {
    ZemInput,
    PreloaderWrapper,
    ZemCollapse,
    ZemButton,
    ZemCardTitle,
    ZemLink,
    ZemCard,
    ZemPreloader,
    CollapseCard,
    XIcon,
    Edit3Icon,
  },
  data() {
    return {
      editElement: false,
      newElement: false,
      isShowEditButton: true,
      elementData: null,
      title: '',
      id: '',
      element: null,
      object: null,
      organization: null,
      createItemLoading: false,
    }
  },
  mounted() {
    this.getPeople()
  },
  methods: {
    formattedPhone,
    phoneTrim,
    getPeople() {
      this.$store.commit('other/onChangeLoadingItem', true)
      const {id} = this.$route.params
      PeopleAndCompaniesService.getPeople(id).then(r => {
        this.element = {
          ...r.data.data,
          phone: formattedPhone(r.data.data.phone),
        }

        const {priority_organization_id, priority_organization} = r.data.data

        const organizationLogic = priority_organization_id !== ''

        if (organizationLogic) {
          this.organization = priority_organization
        } else {
          this.organization = null
        }

        this.$store.commit('other/onChangeLoadingItem', false)
      })
    },
    saveData() {
      this.createItemLoading = true
      const {id} = this.$route.params
      const {title, fio, phone, amocrm_contact_id} = this.element
      PeopleAndCompaniesService.updateCompany(
        id,
        {
          title,
          fio,
          phone: phoneTrim(phone),
          amocrm_contact_id,
        },
        true
      ).then(() => {
        this.createItemLoading = false
        this.editElement = false
        this.getPeople()
      })
    },
    formatDateTime(utc) {
      return moment(utc, 'X').format('DD.MM.YYYY HH:mm')
    },
    closeRightSidebar() {
      this.editElement = false
      this.newElement = false
      this.$router.push({name: 'peoples'})
      this.$emit('on-close')
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
  },
}
</script>

<style lang="scss" scoped></style>
